import React from "react";
import "./SpecialEvent.css";
import specialImg from "../../../Assets/ev1.png";
import specialImg2 from "../../../Assets/ev2.png";
import specialImg3 from "../../../Assets/ev3.png";
import specialImg4 from "../../../Assets/ev4.png";

function SpecialEvent() {
  return (
    <div className="section-special">
      <div className="special-content">
        <h2>
          SATURDAY LIVE MUSIC <br />
          7:00PM TO 10.00PM <br />
        </h2>
        <div className="special-content-img">
          <img alt="special" src={specialImg} />
          <img alt="special" src={specialImg2} />
          <img alt="special" src={specialImg3} />
          <img alt="special" src={specialImg4} />
        </div>
      </div>
    </div>
  );
}

export default SpecialEvent;
