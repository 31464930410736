import React from "react";
import Hero from "./Hero/Hero";
import AmazingMeal from "./AmazingMeal/AmazingMeal";
import Timing from "./Timing/Timing";
import SpecialEvent from "./SpecialEvent/SpecialEvent";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://austinsbbqaddison.com/" />
      </Helmet>
      <Hero />
      <AmazingMeal />
      <Timing />
      <SpecialEvent />
    </div>
  );
};

export default Home;
