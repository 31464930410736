import React from "react";
import "./AmazingMeal.css";
import { NavLink } from "react-router-dom";

import brisket1 from "../../../Assets/brisket.png";
import pork1 from "../../../Assets/pork.png";
import food1 from "../../../Assets/food-1.png";
import food2 from "../../../Assets/food-2.png";
import food3 from "../../../Assets/food-3.png";
import food4 from "../../../Assets/food-4.png";
import food5 from "../../../Assets/food-5.png";
import food6 from "../../../Assets/food-6.png";
import food7 from "../../../Assets/food-7.png";
import food8 from "../../../Assets/food-8.png";
import food9 from "../../../Assets/image16.jpg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BsFillStarFill } from "react-icons/bs";

const AmazingMeal = () => {
  const items = [
    { id: 1, url: brisket1 },
    { id: 2, url: pork1 },
    { id: 3, url: food1 },
    { id: 5, url: food3 },
    { id: 6, url: food4 },
    { id: 7, url: food5 },
    { id: 8, url: food6 },
    { id: 10, url: food8 },
    { id: 11, url: food9 },
  ];

  const settings = {
    adaptiveHeight: true,
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="section section-amazing">
      <div className="container">
        <div className="amazing-container">
          <div className="amazing-carousal">
            <Slider {...settings}>
              {items.map((item) => (
                <div key={item.id}>
                  <img src={item.url} alt="pic" />
                </div>
              ))}
            </Slider>
          </div>

          <div className="amazing-content">
            <h2 className="heading-secondary">
              Amazing BBQ & BEER
              <br />
              <span>
                GREAT ENTERTAINMENT In Our Bar / Lounge. <br />
                <NavLink
                  style={{ color: " var(--color-primary)" }}
                  className="nav-link"
                  to="/bookevents"
                >
                  Book Events Here
                </NavLink>
              </span>
            </h2>
            <p>
              Come Hungry! We Will Feed Y'all Fine Texas BBQ <br /> CALL
              972-803-4383 or ORDER ONLINE
            </p>

            <blockquote>Austin's Bar B Que is "Authentic Texas" BBQ</blockquote>
          </div>
        </div>

        <div className="amazing-card-container">
          <div className="amazing-card amazing-card-left">
            <img src={food2} alt="brisket" className="amazing-card-image" />
            <div className="amazing-card-content">
              <div className="amazing-card-title">
                <BsFillStarFill color="#c3512f" />

                <h3 className="heading-tertiary"> the tastiest foods</h3>
              </div>
              <p>Meat Lovers</p>
            </div>
          </div>
          <div className="amazing-card amazing-card-middle">
            <img src={food7} alt="pork" className="amazing-card-image" />
            <div className="amazing-card-content">
              <div className="amazing-card-title">
                <BsFillStarFill color="#c3512f" />

                <h3 className="heading-tertiary"> Best Cooked</h3>
              </div>
              <p>RIBS</p>
            </div>
          </div>
          <div className="amazing-card amazing-card-right">
            <img src={food6} alt="pork" className="amazing-card-image" />
            <div className="amazing-card-content">
              <div className="amazing-card-title">
                <BsFillStarFill color="#c3512f" />

                <h3 className="heading-tertiary"> Made With Love</h3>
              </div>
              <p>Brisket</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmazingMeal;
