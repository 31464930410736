import { Blocks } from "react-loader-spinner";
import "./UI.css";

export const PageLoadSpin = () => {
  return (
    <div className=" loader">
      <Blocks className=" spinner" />
    </div>
  );
};
