import React from "react";
import "./Footer.css";
import { BsFacebook, BsInstagram, BsTwitter } from "react-icons/bs";
import qrCode from "../../Assets/qr.png";
import gMapIcon from "../../Assets/google-maps.png";
import giftCard from "../../Assets/gift-card.jpg";
import { MdOutlineCopyright } from "react-icons/md";
import kjLogo from "../../Assets/kj-logo.png";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="grid-container">
          <div className="footer-grid-item">
            <div className="footer-icon-div">
              <a
                className="footer-icon"
                href="https://www.facebook.com/austinsbbqaddison"
              >
                <BsFacebook className="social-icon-img" />
              </a>
              <a
                className="footer-icon"
                href="https://www.instagram.com/austinsbbqaddison/"
              >
                <BsInstagram className="social-icon-img" />
              </a>
              <a className="footer-icon" href="https://twitter.com/austinsbbq1">
                <BsTwitter className="social-icon-img" />
              </a>
            </div>
            <h3>Austin's Bar-B-Que</h3>
            <p>16101 Addison Rd</p>
            <p>Addison, TX, 75001</p>
            <p>Restaurant: 972-803-4383</p>
            <p>Catering: 682-262-7553</p>
            <a href="http://www.austinsbbqaddison.com">
              <p>www.austinsbbqaddison.com</p>
            </a>
            <p>austinsbbqcatering@gmail.com</p>
          </div>
          <div className="footer-grid-item gift-card">
            <h3>Gift Cards</h3>
            <a href="https://www.clover.com/online-ordering/austins-bar-b-que-addison/giftcard">
              <img src={giftCard} alt="gift-card" />
            </a>
          </div>
          <div className="footer-grid-item qr-code">
            <h3>Scan to Order Now</h3>
            <img src={qrCode} alt="qr-code" />
          </div>
          <div className="footer-grid-item">
            <h3>Directions</h3>
            <a href="https://www.google.com/maps/place/Austin's+Bar+B+Que/@32.9688944,-96.8328083,17z/data=!3m1!4b1!4m5!3m4!1s0x864c210806ff71f7:0x4d0755328a558899!8m2!3d32.9688899!4d-96.8306196">
              <img id="google-map-icon" src={gMapIcon} alt="gMap-log" />
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>
          <MdOutlineCopyright />
        </p>
        <p>{currentYear} Austin's Bar-B-Que All rights reserved</p>
      </div>
      <div className="powered-by">
        <p>powered by</p>
        <img src={kjLogo} alt="logo" />
      </div>
    </div>
  );
};

export default Footer;
