import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../Assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };
  return (
    <div className="header">
      <div className="container">
        <div className="nav-bar">
          <Link to="/">
            <img src={logo} alt="logo" style={{ width: "120px" }} />
          </Link>

          <ul className="nav-mob">
            <li>
              <NavLink className="nav-mob-link" to="/menu">
                Menu
              </NavLink>
            </li>
            <a href="https://www.clover.com/online-ordering/austins-barbque-addison">
              <button>Order Now</button>
            </a>
          </ul>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li onClick={handleClick}>
              <NavLink className="nav-link navlink-home" to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-menu" onClick={handleClick}>
              <NavLink className="nav-link" to="/menu">
                Menu
              </NavLink>
            </li>
            <li onClick={handleClick}>
              <a
                href="https://www.clover.com/online-ordering/austins-bar-b-que-addison"
                className="nav-link"
                to="/delivery"
              >
                Delivery
              </a>
            </li>
            <li onClick={handleClick}>
              <NavLink className="nav-link" to="/catering">
                Catering
              </NavLink>
            </li>
            <li onClick={handleClick}>
              <a
                className="nav-link"
                href="https://www.clover.com/online-ordering/austins-barbque-addison/giftcard"
              >
                Gift Cards
              </a>
            </li>
            <li onClick={handleClick}>
              <NavLink className="nav-link" to="/bookevents">
                Book Events
              </NavLink>
            </li>
            <li onClick={handleClick}>
              <NavLink className="nav-link" to="/careers">
                Careers
              </NavLink>
            </li>
            <li onClick={handleClick}>
              <NavLink className="nav-link" to="/aboutus">
                About us
              </NavLink>
            </li>

            <li className="nav-menu" onClick={handleClick}>
              <a href="https://www.clover.com/online-ordering/austins-barbque-addison">
                <button>Order Now</button>
              </a>
            </li>
          </ul>

          <div className="hamburger" onClick={handleClick}>
            {click ? (
              <FaTimes size={20} style={{ color: "#fff" }} />
            ) : (
              <FaBars size={20} style={{ color: "#fff" }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
