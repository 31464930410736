import React from "react";
import "./Timing.css";

const Timing = () => {
  return (
    <div className=" section-timing">
      <div className="timing-content">
        <h2>Hours of Operation</h2>
        <ul className="timing-ul">
          <li>Monday: 11:00AM - 9:00PM</li>
          <li>Tuesday: 11:00AM - 9:00PM</li>
          <li>Wednesday: 11:00AM - 9:00PM </li>
          <li>Thursday: 11:00AM - 9:00PM</li>
          <li>Friday: 11:00AM - 9:00PM</li>
          <li> Saturday: 11:00AM - 9:00PM</li>
          <li>Sunday: Closed</li>
        </ul>
      </div>
    </div>
  );
};

export default Timing;
