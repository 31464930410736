import { lazy, Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { PageLoadSpin } from "./components/UI/UI";

import Home from "./Pages/Home/Home";
const Menu = lazy(() => import("./Pages/Menu/Menu"));
const Catering = lazy(() => import("./Pages/Catering/Catering"));
const BookEvents = lazy(() => import("./Pages/Book Events/BookEvents"));
const Careers = lazy(() => import("./Pages/Careers/Careers"));
const AboutUs = lazy(() => import("./Pages/About Us/AboutUs"));

function App() {
  return (
    <div>
      <Suspense fallback={<PageLoadSpin />}>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/catering" element={<Catering />} />
          <Route path="/bookevents" element={<BookEvents />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/careers" element={<Careers />} />
        </Routes>
        <Footer />
      </Suspense>
      <Suspense fallback={<PageLoadSpin />}>
        <Outlet />
      </Suspense>
    </div>
  );
}

export default App;
